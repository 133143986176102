import React, { useState } from 'react';
import { allData } from './allDataImports';
import TreeNode from './components/TreeNode';
import ColorKey from './components/ColorKey';
import TabButton from './components/TabButton';
import './App.css'; // We'll create this file next

const InteractiveIntegrationsTree = () => {
  const [activeTab, setActiveTab] = useState('athena');
  const [searchTerm, setSearchTerm] = useState('');
  const [viewType, setViewType] = useState('default');
  const dataSources = {
    default: {
      athena: allData.default.athenaTree,
      epic: allData.default.epicTree,
      meditech: allData.default.meditechTree,
      oracleRevcycle: allData.default.oracleRevcycleTree,
      oracleWin32: allData.default.oracleWin32Tree,
    },
    alternative: {
      athena: allData.alternative.athenaTree,
      epic: allData.alternative.epicTree,
      meditech: allData.alternative.meditechTree,
      oracleRevcycle: allData.alternative.oracleRevcycleTree,
      oracleWin32: allData.alternative.oracleWin32Tree,
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="app-container">
      {/* Header bar with title */}
      <div style={{ 
        height: '56px', 
        backgroundColor: '#022665', 
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '20px'
      }}>
        <h2 style={{ 
          color: '#faf8f7',
          fontSize: '16px',
          fontWeight: '300',
          fontFamily: 'Inter, sans-serif'
        }}>
          Notable Integrations & Data Library (using mock data)
        </h2>
      </div>

      {/* Main content */}
      <div className="main-content">
        <ColorKey />
        <div className="tab-container">
          <div className="tab-buttons">
            <TabButton
              label="Athena"
              isActive={activeTab === 'athena'}
              onClick={() => setActiveTab('athena')}
            />
            <TabButton
              label="Epic"
              isActive={activeTab === 'epic'}
              onClick={() => setActiveTab('epic')}
            />
            <TabButton
              label="Meditech"
              isActive={activeTab === 'meditech'}
              onClick={() => setActiveTab('meditech')}
            />
            <TabButton
              label="Oracle RevCycle"
              isActive={activeTab === 'oracleRevcycle'}
              onClick={() => setActiveTab('oracleRevcycle')}
            />
            <TabButton
              label="Oracle Win32"
              isActive={activeTab === 'oracleWin32'}
              onClick={() => setActiveTab('oracleWin32')}
            />
          </div>
          <div className="toggle-container">
            <span className="toggle-label">View by:</span>
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={viewType === 'alternative'}
                onChange={() => setViewType(viewType === 'default' ? 'alternative' : 'default')}
              />
              <span className="slider">
                <span className="toggle-option category">Category</span>
                <span className="toggle-option integration">Integration</span>
              </span>
            </label>
          </div>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
          />
        </div>
        <div className="tree-container">
          {activeTab && (
            <TreeNode 
              node={dataSources[viewType][activeTab]} 
              searchTerm={searchTerm} 
              isRoot={true} 
              viewType={viewType}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InteractiveIntegrationsTree;