import React, { useState, useEffect } from 'react';

const TreeNode = ({ node, level = 0, searchTerm, isRoot = false, viewType }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasChildren = node.children && node.children.length > 0;

  useEffect(() => {
    if (searchTerm && nodeMatchesSearch(node, searchTerm)) {
      setIsExpanded(true);
    }
  }, [searchTerm, node]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const nodeMatchesSearch = (node, term) => {
    if (!term) return true;
    const lowerTerm = term.toLowerCase();
    return (
      node.name.toLowerCase().includes(lowerTerm) ||
      (node.value && node.value.toLowerCase().includes(lowerTerm))
    );
  };

  const shouldDisplay = (node, term) => {
    if (!term) return true;
    return nodeMatchesSearch(node, term) ||
      (node.children && node.children.some(child => shouldDisplay(child, term)));
  };

  if (!shouldDisplay(node, searchTerm)) {
    return null;
  }

  return (
    <div style={{ marginLeft: level > 0 ? '35px' : '0' }}>
      <div 
        className="flex items-center cursor-pointer hover:bg-gray-100 p-1"
        onClick={toggleExpand}
      >
        <span className="mr-2 flex items-center justify-center" style={{ width: '1em', height: '1em', lineHeight: '1' }}>
          <span style={{ 
            fontSize: '0.7em',
            color: hasChildren ? '#022665' : (node.notImplemented ? '#b81414' : '#227745')
          }}>
            {hasChildren 
              ? (isExpanded ? '▼ ' : '▶ ')
              : (node.notImplemented ? '✗ ' : '✓ ')}
          </span>
        </span>
        <span 
          className={`font-semibold ${node.notImplemented ? 'text-gray-300' : ''}`} 
          style={{ color: node.notImplemented ? '#999999' : '#022665' }}
        >
          {node.name}
          {isRoot && node.coveragePercentage !== undefined && (
            <span className="ml-2 text-sm font-normal">
              <strong>{node.coveragePercentage.toFixed(1)}%</strong> implemented of <strong>known</strong> data elements.
            </span>
          )}
        </span>
        {node.value && (
          <span 
            className={`ml-2 ${node.notImplemented ? 'text-gray-300' : ''}`}
            style={{ color: node.notImplemented ? '#999999' : '#022665' }}
          >
            <span className="font-normal"> ({node.value})</span>
          </span>
        )}
      </div>
      {(isExpanded || searchTerm) && hasChildren && (
        <div>
          {node.children.map((child, index) => (
            <TreeNode 
              key={index} 
              node={child} 
              level={level + 1} 
              searchTerm={nodeMatchesSearch(node, searchTerm) ? '' : searchTerm} 
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TreeNode;