import Papa from 'papaparse';

function calculateCoverage(node) {
  if (!node.children) {
    return {
      implemented: node.notImplemented ? 0 : 1,
      total: 1
    };
  }

  const coverage = node.children.reduce((acc, child) => {
    const childCoverage = calculateCoverage(child);
    return {
      implemented: acc.implemented + childCoverage.implemented,
      total: acc.total + childCoverage.total
    };
  }, { implemented: 0, total: 0 });

  node.coverage = coverage;
  node.coveragePercentage = (coverage.implemented / coverage.total) * 100;

  return coverage;
}

function convertCSVToTreeStructure(data, title) {
  const tree = {
    name: title,
    children: []
  };

  data.forEach(row => {
    let category = tree.children.find(c => c.name === row.Category);
    if (!category) {
      category = { name: row.Category, children: [] };
      tree.children.push(category);
    }

    let subcategory = category.children.find(s => s.name === row['Integration']);
    if (!subcategory) {
      subcategory = { name: row['Integration'], children: [] };
      category.children.push(subcategory);
    }

    subcategory.children.push({
      name: row.Data,
      value: row['Read / Write'],
      notImplemented: row.notImplemented.toLowerCase() === 'true'
    });
  });

  return tree;
}

function convertCSVToAlternativeTreeStructure(data, title) {
  const tree = {
    name: title,
    children: []
  };

  data.forEach(row => {
    let integration = tree.children.find(i => i.name === row['Integration']);
    if (!integration) {
      integration = { name: row['Integration'], children: [] };
      tree.children.push(integration);
    }

    let category = integration.children.find(c => c.name === row.Category);
    if (!category) {
      category = { name: row.Category, children: [] };
      integration.children.push(category);
    }

    category.children.push({
      name: row.Data,
      value: row['Read / Write'],
      notImplemented: row.notImplemented.toLowerCase() === 'true'
    });
  });

  return tree;
}

async function loadCSVData(url) {
  const response = await fetch(url);
  const csvText = await response.text();
  return Papa.parse(csvText, { header: true, skipEmptyLines: true }).data;
}

async function loadAllData() {
  const [athenaData, epicData, meditechData, oracleRevcycleData, oracleWin32Data] = await Promise.all([
    loadCSVData('/athena_data.csv'),
    loadCSVData('/epic_data.csv'),
    loadCSVData('/meditech_data.csv'),
    loadCSVData('/oracle_revcycle_data.csv'),
    loadCSVData('/oracle_win32_data.csv')
  ]);

  const athenaTree = convertCSVToTreeStructure(athenaData, "Athena's integrated data availability is ");
  const epicTree = convertCSVToTreeStructure(epicData, "Epic's integrated data availability is ");
  const meditechTree = convertCSVToTreeStructure(meditechData, "Meditech's integrated data availability is ");
  const oracleRevcycleTree = convertCSVToTreeStructure(oracleRevcycleData, "Oracle RevCycle's integrated data availability is ");
  const oracleWin32Tree = convertCSVToTreeStructure(oracleWin32Data, "OracleWin32's integrated data availability is ");

  const athenaAltTree = convertCSVToAlternativeTreeStructure(athenaData, "Athena's integrated data availability is ");
  const epicAltTree = convertCSVToAlternativeTreeStructure(epicData, "Epic's integrated data availability is ");
  const meditechAltTree = convertCSVToAlternativeTreeStructure(meditechData, "Meditech's integrated data availability is ");
  const oracleRevcycleAltTree = convertCSVToAlternativeTreeStructure(oracleRevcycleData, "Oracle RevCycle's integrated data availability is ");
  const oracleWin32AltTree = convertCSVToAlternativeTreeStructure(oracleWin32Data, "Oracle Win32's integrated data availability is ");

  calculateCoverage(athenaTree);
  calculateCoverage(epicTree);
  calculateCoverage(meditechTree);
  calculateCoverage(oracleRevcycleTree);
  calculateCoverage(oracleWin32Tree);

  calculateCoverage(athenaAltTree);
  calculateCoverage(epicAltTree);
  calculateCoverage(meditechAltTree);
  calculateCoverage(oracleRevcycleAltTree);
  calculateCoverage(oracleWin32AltTree);

  return { 
    default: { athenaTree, epicTree, meditechTree, oracleRevcycleTree, oracleWin32Tree },
    alternative: { athenaTree: athenaAltTree, epicTree: epicAltTree, meditechTree: meditechAltTree, oracleRevcycleTree: oracleRevcycleAltTree, oracleWin32Tree: oracleWin32AltTree }
  };
}

export const allData = await loadAllData();