import React from 'react';

const ColorKey = () => (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
    <span style={{ marginRight: '0.5rem', fontSize: '12px', fontWeight: 'bold' }}>Font Color Key:</span>
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '0.5rem' }}>
      <div style={{ width: '10px', height: '10px', backgroundColor: '#022665', border: '1px solid #595959', marginRight: '0.5rem' }}></div>
      <span style={{ color: '#022665', fontSize: '12px' }}>Implemented</span>
    </div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ width: '10px', height: '10px', backgroundColor: '#999999', border: '1px solid #595959', marginRight: '0.5rem' }}></div>
      <span style={{ color: '#999999', fontSize: '12px' }}>Not Implemented Yet</span>
    </div>
  </div>
);

export default ColorKey;