import React from 'react';
import './TabButton.css'; 

const TabButton = ({ label, isActive, onClick }) => ( 
  <button
    className={`tab-button ${isActive ? 'active' : ''}`}
    onClick={onClick}
  >
    {label}
  </button>
);

export default TabButton;